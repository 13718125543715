<template>
    <modal ref="modalListaDirecciones" titulo="Agrega o escoge una dirección" tamano="modal-lg" adicional="Agregar dirección" no-aceptar no-cancelar @adicional="anadirDireccion">
        <cargando v-if="cargando" />
        <div class="row mx-0 j-center">
            <div v-for="(data, index) in direcciones" :key="index" class="col-xl-8 col-lg-8 col-md-9 col-sm-9 col-12 d-flex mb-3 cr-pointer">
                <i class="icon-location-outline f-20" :class="direccionPrincipal == data.id ? 'text-green' : 'text-muted'" @click="direccionPrincipal = data.id, changeDireccion()" />
                <div class="col f-15 f-500 px-2" :class="direccionPrincipal == data.id ? 'text-general' : 'text-muted'" @click="direccionPrincipal = data.id, changeDireccion()">
                    {{ data.direccion }} {{ data.direccion_anexo }} {{ data.barrio }}
                    <p class="f-400 f-14 mt-1" :class="direccionPrincipal == data.id ? 'text-green' : 'text-muted'">
                        {{ data.nombre }}
                    </p>
                </div>
                <i v-if="idActions != data.id" :class="`icon-dots-vertical f-18 cr-pointer  ${idActions == data.id ? 'text-general' : 'text-muted'}`" @click="idActions = data.id" />
                <el-tooltip content="Editar" placement="bottom" effect="light">
                    <i v-if="idActions == data.id" :class="`icon-pencil-outline f-18 cr-pointer ${idActions == data.id ? 'text-general' : 'text-muted'}`" @click="editar(data)" />
                </el-tooltip>
                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                    <i v-if="idActions == data.id" class="icon-trash-can-outline f-18 cr-pointer" style="color:red" @click="confirmarEliminar" />
                </el-tooltip>
            </div>
            <div v-if="direcciones.length === 0" class="d-middle-center" style="width:100%;height:55vh;">
                <div class="col-auto">
                    <div class="row mx-0 j-center">
                        <img :src="funImagenGeneral(44)" width="180" height="180" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 j-center mt-2">
                <div v-if="!cobertura" class="alert-direction pl-3 mt-1">
                    <i class="icon-alert-circle-outline f-23" />
                    <p class="col-auto pl-0 pr-4">No tenemos con cobertura en tu área</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/direcciones/direcciones';

export default {
    data(){
        return {
            cargando: false,
            cobertura: true,
            direcciones: [],
            direccionPrincipal: null,
            idActions: null,
        }
    },
    methods: {
        toggle(bandera){
            if(bandera === 'eliminar'){
                this.deleteDireccion();
            } else {
                this.limpiar();
                this.getDirecciones();
            }
            this.$refs.modalListaDirecciones.toggle();
        },

        async getDirecciones(){
            try {
                this.cargando = true;
                const {data} = await Service.getDirecciones();
                this.direccionPrincipal = data.principal;
                this.direcciones = data.direcciones;
                this.cobertura = data.cobertura;
                this.$emit('cobertura',this.cobertura)
            } catch(e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }
        },

        async changeDireccion(){
            try {
                const {data} = await Service.putChangeDireccion(this.direccionPrincipal);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('update')
                this.$store.dispatch('auth/consultarUsuario')
                this.idActions = null;
            } catch(e){
                this.errorCatch(e)
            }
        },

        async confirmarEliminar(){
            if(this.idActions === this.direccionPrincipal){
                return this.notificacion('Espera', 'No es posible eliminar la dirección principal', 'warning');
            }
            this.$refs.modalListaDirecciones.toggle();
            this.$emit('acciones', 'eliminarDireccion');
        },

        async deleteDireccion(){
            try {
                const {data} = await Service.deleteDireccion(this.idActions);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.direcciones = data.direcciones;
                this.idActions = null;
            } catch(e){
                this.errorCatch(e)
            }
        },

        anadirDireccion(){
            this.$refs.modalListaDirecciones.toggle();
            this.$emit('acciones', 'nuevaDireccion');
        },

        editar(data){
            this.$refs.modalListaDirecciones.toggle();
            this.$emit('editarDireccion', data);
        },

        limpiar(){
            this.idActions = null;
        }   
    }
}
</script>
<style lang="scss" scoped>
.bg-fondo{
    background-color: #F6F9FB !important;
    .box{
        height: 84vh;
        background-color: #FFFFFF;
        border-radius: 12px;
        width: 655px;
        box-shadow: 0px 1px 4px #00000014 !important;
        .title-box{
            font-size: 30px;
            color: #000000;
            font-weight: 500;
        }
    }
}

.alert-direction{
    display: flex;
    align-items: center;
    color: #FFFFFF;
    border:1px solid #FF8B32;
    color: #FF8B32;
    border-radius: 15px;
    height: 30px;
    p{
        font-size: 12px;

    }
}

</style>